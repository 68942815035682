<template>
  <v-card>
    <v-snackbar
      v-model="snackbar"
      v-if="error"
      bottom
      right
      auto-height
      :timeout="10000"
    >
      <v-alert :color="error.color" outline :value="true">
        <p class="title">{{ error.title }}</p>
        <v-layout>
          <p>{{ error.message }}</p>
        </v-layout>
      </v-alert>
      <v-btn :color="error.color" flat @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
export default {
  name: 'ErrorBoxDialog',
  components: {},
  props: [],
  data: () => ({
    error: null,
    snackbar: false
  }),
  beforeDestroy() {
    this.$bus.$off('APP_MESSAGE')
  },
  mounted() {
    this.$bus.$on('APP_MESSAGE', data => {
      this.error = data
      // Animation won't occur without nextTick
      this.$nextTick(() => (this.snackbar = true))
    })
  }
}
</script>
<style scoped>
.v-alert.v-alert--outline {
  border: none !important;
}
</style>
